<template>
  <v-container>
    <v-row class="mt-2">
      <v-col>
        <h2>日冷倉協会員名簿作成</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-body-1 text-error">※現在の会員情報を元に作成します。</span>
      </v-col>
    </v-row>

    <v-card class="mt-5 mb-5">
      <v-card-title>
        <v-row>
          <v-col cols="9">会員名簿作成データ</v-col>
          <v-col cols="3" class="text-center">
            <v-btn color="primary" width="200"
                  :disabled="loading"
                  @click="makeBuneishaList()">
              <v-icon :small="true">mdi-download</v-icon>
              ダウンロード
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <v-card class="mt-5 mb-5">
      <v-card-title>
        <v-row>
          <v-col cols="6"> 地区協会確認資料 </v-col>
          <v-col cols="3">
            <v-select label="都道府県"
                  v-model="targetAssociation.regionPref"
                  :items="prefListAddAll"
                  item-text="text"
                  item-value="value"
                  dense
                  :loading="loading"
                  :disabled="loading"
            >
            </v-select>
          </v-col>
          <v-col cols="3" class="text-center">
            <v-btn color="primary" width="200"
                  @click="makeAssociationList(targetAssociation.regionPref)"
                  dense
                  :disabled="loading"
            >
              <v-icon :small="true">mdi-download</v-icon>
              ダウンロード
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <v-card class="mt-5 mb-5">
      <v-card-title>
        <!-- <v-row class="justify-space-between"> -->
        <v-row>
          <v-col cols="6">
            メールアドレス調査
          </v-col>
          <v-col cols="3">
            <v-select label="県協会確認"
                  v-model="targetAssociation.mailAdressPrefAssociation"
                  :items="associationList"
                  item-text="associationName"
                  item-value="associationId"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  class="mb-5"
            ></v-select>
            <v-select label="大手5社"
                  v-model="targetAssociation.mailAdressBig5List"
                  :items="big5List"
                  item-text="text"
                  item-value="text"
                  dense
            ></v-select>
          </v-col>
          <v-col cols="3" class="text-center">
            <div>
              <v-btn color="primary" width="200"
                    @click="makeMailAddressResearch(targetAssociation.mailAdressPrefAssociation)"
                    dense
                    :disabled="loading"
                    class="mb-7"
              >
                <v-icon :small="true">mdi-download</v-icon>
                ダウンロード
              </v-btn>
            </div>
            <div>
              <v-btn color="primary" width="200"
                    :disabled="loading"
                    @click="makeMailAddressBig5(targetAssociation.mailAdressBig5List)"
              >
                <v-icon :small="true">mdi-download</v-icon>
                ダウンロード
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <v-card class="mt-5 mb-5">
      <v-card-title>
        <v-row>
          <v-col cols="9">会員データ出力</v-col>
          <v-col cols="3" class="text-center">
            <div>
            <v-btn color="primary" width="200"
                  :disabled="loading"
                  @click="makeMemberListExcel()"
                  class="mb-7" >
              <v-icon :small="true">mdi-download</v-icon>
              ダウンロード
            </v-btn>
            </div>
            <div>
              <v-btn color="primary" width="200"
                    :disabled="loading"
                  @click="makeMemberHistoryExcel()">
                <v-icon :small="true">mdi-download</v-icon>
                ダウンロード（増減）
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <!-- <v-card class="mt-5 mb-5">
      <v-card-title>
        <v-row>
          <v-col cols="6"> 宛名印刷データ </v-col>
          <v-col cols="3">
            <v-select label="宛先"
                  v-model="targetAssociation.addressPrint"
                  :items="addressPrintList"
                  dense
                  :loading="loading"
                  :disabled="loading"
            >
            </v-select>
          </v-col>
          <v-col cols="3" class="text-center">
            <v-btn color="primary" width="200"
                  @click="makeAddressPrintData(targetAssociation.addressPrint)"
                  dense
                  :disabled="loading"
            >
              <v-icon :small="true">mdi-download</v-icon>
              ダウンロード
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card> -->

    <!------------------------------------------------------------------------>
    <!-- ぐるぐる -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
      <div>
        <h3 class="center mt-5">{{actionMessage}}</h3>
        <Spinner size="150" class="spinner" ></Spinner>
      </div>
    </modal>

  </v-container>
</template>

<script>
import CreateMemberRoster from "../model/createMemberRoster";
import Association from "../model/m_association";
import Download from "../model/download";
import { saveAs } from "file-saver";
import Spinner from 'vue-simple-spinner'

import Mixin from "../mixins/const.js";

import Message from "../Lib/Message";
import Debug from "../Lib/Debug";


export default {
  mixins: [Mixin],

  components: {
    Spinner,
  },

  data: () => ({
    loading: false,

    prefListAddAll: [],
    associationList: [],
    addressPrintList: ["都道府県協会", "会員企業"],

    targetAssociation: {
      regionPref: 0,
      mailAdressPrefAssociation: "0",
      mailAdressBig5List: "マルハニチロ物流",
      addressPrint: "都道府県協会",
    },

    // 検索条件
    searchCondition: {
      type: 0, // type:0が冷蔵
    },

    actionMessage: '',
  }),

  // ------------------------------------------------------------------------
  // ライフサイクルフック
  created: function() {
  },

  mounted: function() {
    this.loadList();
  },

  // ------------------------------------------------------------------------
  // メソッド
  methods: {
    async loadList() {
      Debug.log("function[loadList]");
      this.loading = true;

      // DBから協会情報取得
      Association.search(this.searchCondition)
          .then(res => {
            if (res && res.data) {
              this.associationList = [{ associationName: "すべて", associationId: "0" }];
              this.associationList = this.associationList.concat(res.data);   // 「すべて」がある既存associationListに冷蔵倉庫協会一覧を追加

              // 日冷倉を除く
              this.associationList = this.associationList.filter(a => a.associationId != "000000");

              Debug.log2("associationList->", this.associationList);
            }
            this.loading = false;
          })
          .catch((err) => {
            Message.err(err, "協会情報を取得できませんでした");
          });


      this.prefListAddAll = [{ text: "すべて", value: 0 }];
      this.prefListAddAll = this.prefListAddAll.concat(this.prefList);
      Debug.log2("prefListAddAll->", this.prefListAddAll);
    },

    //-------------------------------------------------------------------------
    // 名簿作成（文栄社名簿）
    async makeBuneishaList() {
      Debug.log("function[makeBuneishaList]");

      if (!confirm("名簿を作成します。")) return;

      this.actionMessage = "名簿を作成しています。しばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // 名簿作成
      CreateMemberRoster.makeBuneishaRoaster()
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成した名簿をダウンロードします。よろしいですか？")) return;

              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "名簿を作成できませんでした");
          })
          .finally(() => {
            this.$modal.hide('modal-dialog');
          });
    },

    // 名簿作成（地区協会確認）
    async makeAssociationList(prefId) {
      Debug.log("function[makeAssociationList]");

      if (!confirm("名簿を作成します。")) return;

      this.actionMessage = "名簿を作成しています。しばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // 名簿作成
      CreateMemberRoster.makeAssociationRoaster(prefId)
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成した名簿をダウンロードします。よろしいですか？")) return;

              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "名簿を作成できませんでした");
          })
          .finally(() => {
            this.$modal.hide('modal-dialog');
          });
    },

    // 名簿作成（メールアドレス調査）
    async makeMailAddressResearch(associationId) {
      Debug.log("function[makeMailAddressResearch]");

      if (!confirm("名簿を作成します。")) return;

      this.actionMessage = "名簿を作成しています。しばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // 名簿作成
      CreateMemberRoster.makeMailAddressResearch(associationId)
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成した名簿をダウンロードします。よろしいですか？")) return;

              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            } else {
                // データ無しに対応
                alert("該当するデータがありませんでした。");
            }
          })
          .catch((err) => {
            Message.err(err, "名簿を作成できませんでした");
          })
          .finally(() => {
            this.$modal.hide('modal-dialog');
          });
    },


    // 名簿作成（メールアドレス調査）
    async makeMailAddressBig5(companyId) {
      Debug.log("function[makeMailAddressBig5]");

      if (!confirm("名簿を作成します。")) return;

      this.actionMessage = "名簿を作成しています。しばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // 名簿作成（大手５社）
      CreateMemberRoster.makeMailAddressBig5(companyId)
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成した名簿をダウンロードします。よろしいですか？")) return;

              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "名簿を作成できませんでした");
          })
          .finally(() => {
            this.$modal.hide('modal-dialog');
          });
    },

    // EXCELデータ作成（会員データ）
    async makeMemberListExcel() {
      Debug.log("function[makeMemberListExcel]");

      if (!confirm("会員データファイルを出力します。")) return;

      this.actionMessage = "会員データファイルを作成しています。しばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // EXCEL出力
      CreateMemberRoster.makeMemberListExcel()
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成した名簿をダウンロードします。よろしいですか？")) return;

              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "名簿を作成できませんでした");
          })
          .finally(() => {
            this.$modal.hide('modal-dialog');
          });
    },

    // EXCELデータ作成（会員増減履歴）
    async makeMemberHistoryExcel() {
      Debug.log("function[makeMemberHistoryExcel]");

      if (!confirm("会員増減データを出力します。")) return;

      this.actionMessage = "会員増減データを作成しています。しばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // EXCEL出力
      CreateMemberRoster.makeMemberHistoryExcel()
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成したデータをダウンロードします。よろしいですか？")) return;

              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "名簿を作成できませんでした");
          })
          .finally(() => {
            this.$modal.hide('modal-dialog');
          });
    },

    // 宛名印刷マクロ用データ作成
    async makeAddressPrintData(dataType) {
      Debug.log("function[makeAddressPrintData]");

      if (!confirm("宛名印刷データを作成します。")) return;

      this.actionMessage = "宛名印刷データを作成しています。しばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // 名簿作成
      CreateMemberRoster.makeAdressPrintData(dataType)
          .then(res =>{
            if (res && res.data) {
              if (!confirm("作成したデータをダウンロードします。よろしいですか？")) return;

              const file_info = res.data;
              Debug.log2("Download ファイル情報->", file_info);

              // ファイルダウンロード
              this.download(file_info);
            }
          })
          .catch((err) => {
            Message.err(err, "名簿を作成できませんでした");
          })
          .finally(() => {
            this.$modal.hide('modal-dialog');
          });
    },


    //-------------------------------------------------------------------------
    // ファイルダウンロード
    async download(file_info) {
      Debug.log("function[download]");

      // ダウンロード
      Download.download(file_info.Download_path)
        .then(res => {
          saveAs(res.data, file_info.File_name);
        })
        .catch((err) => {
          Message.err(err, "ダウンロードできませんでした");
        })

    },

  },

}
</script>


<style scoped>

.spinner {
  margin: 50px;
}

</style>
