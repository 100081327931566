import Axios from "axios";
import token from "./setToken";

export default {
  async makeBuneishaRoaster() {
    try {
      return await Axios.get("/api/CreateMemberRoster/Buneisha", token.setTokenToHeader());
      
    } catch (error) {
      console.error("名簿を作成できません[download]");
      throw error;
    }
  },

  async makeAssociationRoaster(prefId) {
    try {
      return await Axios.get("/api/CreateMemberRoster/Association/" + prefId, token.setTokenToHeader());
      
    } catch (error) {
      console.error("名簿を作成できません[download]");
      throw error;
    }
  },

  async makeMailAddressResearch(associationId) {
    try {
      return await Axios.get("/api/CreateMemberRoster/MailAddress/" + associationId, token.setTokenToHeader());
      
    } catch (error) {
      console.error("名簿を作成できません[download]");
      throw error;
    }
  },

  async makeMailAddressBig5(companyName) {
    try {
      return await Axios.get("/api/CreateMemberRoster/MailAddressBig5/" + companyName, token.setTokenToHeader());
      
    } catch (error) {
      console.error("名簿を作成できません[download]");
      throw error;
    }
  },

  async makeMemberListExcel() {
    try {
      return await Axios.get("/api2/CreateMemberRoster/MemberList", token.setTokenToHeader());
      
    } catch (error) {
      console.error("会員データファイルを作成できません[download]");
      throw error;
    }
  },

  async makeMemberHistoryExcel() {
    try {
      return await Axios.get("/api/CreateMemberRoster/MemberHistory", token.setTokenToHeader());
      
    } catch (error) {
      console.error("会員データファイルを作成できません[download]");
      throw error;
    }
  },

  async makeAdressPrintData(dataType) {
    try {
      return await Axios.get("/api/CreateMemberRoster/AddressPrintData/" + dataType, token.setTokenToHeader());
      
    } catch (error) {
      console.error("宛名印刷データを作成できません[download]");
      throw error;
    }
  },

  async download(excelTpl, targetAssociation, fileName) {
    try {
      const res = await Axios.get("/api/CreateMemberRoster/" + excelTpl + "/" + targetAssociation + "/" + fileName, token.setTokenToHeader(), {
        responseType: "arraybuffer",
        headers: {
          "Accept": "application/zip",
        },
      });

      return res;

    } catch (error) {
      console.error("名簿を作成できません[download]");
      throw error;
    }
  }

};
